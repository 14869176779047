<template>
    <div class="container-auth-success">
        <van-icon class="icon" name="checked" />
        <div class="title">已授权</div>
        <div class="sub-title">企业可继续使用小鹅通·企微助手应用</div>
        <router-link class="btn-link" :to="'/home'">使用应用</router-link>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container-auth-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100vh;
    padding: 2rem 0;
    box-sizing: border-box;
    .icon {
        font-size: 1.4rem;
        color: #296ded;
        margin-bottom: 0.6rem;
    }
    .title {
        font-size: 0.36rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
    }
    .sub-title {
        color: #999999;
        margin-bottom: 0.2rem;
    }
    .btn-link {
        background: #1f65ecf2;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.4rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        font-size: 0.32rem;
        letter-spacing: 0.01rem;
        margin-top: auto;
    }
}
</style>
